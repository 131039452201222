import * as React from 'react';
import Typography from '@mui/material/Typography';

const ID = "8c755ecf-cbfd-442b-97f5-1fcc8c9d3b30"

const ArmsIndicator3 = ({ metrics, setMetrics, metricsData }) => {
  const [data, setData] = React.useState(false)

  React.useEffect(() => {
    if (!metrics.find(metric => metric === ID)) {
      metrics.push(ID)
      setMetrics(metrics)
    }

    if (metricsData) {
      setData(metricsData[ID])
    }
  }, [metricsData, metrics, setMetrics])

  return (
    (data === true) ? <Typography style={{ padding: '5px', color: 'green' }} >Open </Typography> :
      <Typography style={{ padding: '5px', color: 'red' }}>Closed </Typography>
  )
}
export default ArmsIndicator3;