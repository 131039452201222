import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Title from './Title';
import ky from 'ky';

import { WEBHOOK_URL } from '../config';

const RELATIVE_VELOCITY_ID = '56e0abb0-7eef-496f-af48-9142b423eae0';
const paperStyle = { p: 2, display: 'flex', flexDirection: 'column' };

const DebugObstaclePanel = ({ metrics, setMetrics, metricsData }) => {
  const [data, setData] = React.useState('?')
  const [enabled, setEnabled] = React.useState(true)
  const [isPaused, setIsPaused] = React.useState(false)
  const [thrustDuration, setThrustDuration] = React.useState(0)
  const [didAccelerate, setDidAccelerate] = React.useState(true)
  const [hasPreviousMovement, setHasPreviousMovement] = React.useState(null)

  React.useEffect(() => {
    if (!metrics.find(metric => metric === RELATIVE_VELOCITY_ID)) {
      metrics.push(RELATIVE_VELOCITY_ID)
      setMetrics(metrics)
    }

    if (metricsData) {
      setData(metricsData[RELATIVE_VELOCITY_ID])
    }

    return () => {
      metrics.splice(metrics.findIndex(x => x.id === RELATIVE_VELOCITY_ID), 1)
      setMetrics(metrics)
    }
  }, [metricsData, metrics, setMetrics])

  function simulateDebris() {
    var thrust;
    var isAccelerating;
    if (Math.abs(data) > 0.1) {
      console.log("Going to try to stop.")
      thrust = 3000.0 / 0.01 * (1.0 - 1.0 / (Math.exp(Math.abs(data) / 2000.0)));
      console.log(`Thrust of ${thrust}`)
      if (data > 0) {
        isAccelerating = false;
      } else {
        isAccelerating = true;
      }
    } else {
      const desiredSpeed = 2;
      console.log("Going to try to decelerate.")

      thrust = 3000.0 / 0.01 * (1.0 - 1.0 / (Math.exp(Math.abs(desiredSpeed) / 2000.0)));
      console.log(`Thrust of ${thrust}`)
      isAccelerating = false;
    }

    let body = {
      "type": "thrust",
      "forward": isAccelerating,
      "time": thrust * 1000
    }
    setEnabled(false)
    setThrustDuration(thrust)
    setDidAccelerate(isAccelerating)
    setIsPaused(true)
    setHasPreviousMovement(true)

    console.log(`Is going to ${isAccelerating ? 'accelerate' : 'decelerate'} with a thrust of ${thrust}sec.`)

    ky.post(
      WEBHOOK_URL,
      { json: body }
    ).json().then(rows => setEnabled(true))
  }

  function resumeCourse() {
    const body = { "type": "thrust", "forward": !didAccelerate, "time": thrustDuration * 1000 }

    setEnabled(false)
    setThrustDuration(thrustDuration)
    setDidAccelerate(!didAccelerate)
    setIsPaused(false)
    setHasPreviousMovement(true)

    ky.post(
      WEBHOOK_URL,
      { json: body }
    ).json().then(rows => setEnabled(true))
  }

  var previousMovement = <></>;
  if (hasPreviousMovement) {
    const word = didAccelerate ? 'accelerate' : 'decelerate';
    previousMovement = (<Typography>
      Trying to {word} during {thrustDuration.toFixed(3)}sec.
    </Typography>);
  }

  return (
    <Grid item xs={12} md={12} lg={12} sx={{ paddingTop: 3 }}>
      <Paper sx={paperStyle}>
        <Title>Unexpected Debris</Title>
        <Typography>
          {
            data === "?" ?
              `Fetching speed...` :
              `Current speed of ${(+data).toFixed(3)}m/s`
          }
        </Typography>
        <Button
          variant="contained"
          onClick={isPaused ? resumeCourse : simulateDebris}
          disabled={
            !enabled ||
            data === "?"
          }
          sx={{ marginTop: 1 }}
        >
          {isPaused ? "Resume Course" : "Make Debris Appear"}
        </Button>
        {previousMovement}
      </Paper>
    </Grid>
  );
}

export default DebugObstaclePanel;
