import React from "react";
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';


const ProgressBar = ({ completed, id, metrics, setMetrics, metricsData }) => {

  const [initial, setInitial] = React.useState(0);

  React.useEffect(() => {
    if (!metrics.find(metric => metric === id)) {
      metrics.push(id)
      setMetrics(metrics)
    }

    if (metricsData && metricsData[id]) {
      setInitial(metricsData[id])
    }

    return () => {
      metrics.splice(metrics.findIndex(x => x.id === id), 1)
      setMetrics(metrics)
    }
  }, [metricsData, id, metrics, setMetrics])

  const farWidth = (1 - Math.log(50) / Math.log(completed)) * 100;
  const closeWidth = (Math.log(50) / Math.log(completed) - Math.log(5) / Math.log(completed)) * 100;
  const captureWidth = (Math.log(50) / Math.log(completed) - Math.log(5) / Math.log(completed)) * 100;

  const progressWidth = (1 - Math.log(initial + 1) / Math.log(completed)) * 100;

  return (
    <>
      <Typography>
        {initial.toFixed(2)}m away from the Vespa.
      </Typography>

      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ borderBottom: "none", backgroundColor: "#6A1B9A" }}
              sx={{
                width: `${progressWidth}%`,
                borderRadius: "15px 0 0 15px",
                transition: "width 0.5s ease-in-out"
              }}
            />
            <TableCell
              style={{ borderBottom: "none", backgroundColor: "#8E24AA" }}
              sx={{ width: '100%', borderRadius: "0 15px 15px 0" }}
            />
          </TableRow>
        </TableHead>
      </Table>

      <Table sx={{ mt: 1 }}>
        <TableHead>
          <TableRow>
            <TableCell
              style={{ borderBottom: "none", backgroundColor: "#6A1B9A" }}
              sx={{ width: `${farWidth}%`, borderRadius: "15px 0 0 15px" }}
            >
              <Typography variant="button" sx={{ fontWeight: 'bold' }}>
                Far <span style={{ color: "silver" }}> >50m </span>
              </Typography>

            </TableCell>
            <TableCell
              style={{ borderBottom: "none", backgroundColor: "#7B1FA2" }}
              sx={{ width: `${closeWidth}%` }}
            >
              <Typography variant="button" sx={{ fontWeight: 'bold' }}>
                Close <span style={{ color: "silver" }}> >5m </span>
              </Typography>
            </TableCell>
            <TableCell
              style={{ borderBottom: "none", backgroundColor: "#8E24AA" }}
              sx={{ width: `${captureWidth}%`, borderRadius: "0 15px 15px 0" }}
            >
              <Typography variant="button" sx={{ fontWeight: 'bold' }}>Capture</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
      </Table>
    </>
  );
}

export default ProgressBar;
