import React, { useState, useEffect } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


function Clock() {
	const [dateState, setDateState] = useState(new Date());
    useEffect(() => {
           setInterval(() => setDateState(new Date()), 1000);
    }, []);

	const date = dateState.toLocaleDateString('en-GB', {
		day: 'numeric',
		month: 'numeric',
		year: 'numeric',
	});
	const time = dateState.toLocaleString('en-GB', {
		hour: 'numeric',
		minute: 'numeric',
		second: 'numeric',
		hour24: true,
	});

	return (
		<Grid container spacing={3}  sx={{pt: "22px"}}>
			<Grid item xs={6} md={6} lg={6}>
				<Typography textAlign={"center"}>{date}</Typography>
			</Grid>
			<Grid item xs={6} md={6} lg={6}>
				<Typography textAlign={"center"}>{time}</Typography>
			</Grid>
		</Grid>
	);
}

export default Clock;
