import * as React from 'react';
import Paper from '@mui/material/Paper';
import Title from '../Title';
import LeanspaceWidget from '../LeanspaceWidget';
import ProgressBar from '../ProgressBar';
import LogInfo from '../LogInfo';
import Grid from '@mui/material/Grid';

const POSITION_METRIC_ID = "9d514368-b41d-41cb-b8f7-d7f8d1281ef5";
const REL_VELOCITY_DASHBOARD = "57234290-b1c2-41c8-9845-741385ab9b60";

const paperStyle = { p: 2, display: 'flex', flexDirection: 'column', paddingBottom: '65px' };

const MainDashboard = ({ metrics, setMetrics, metricsData }) => {

  return (<>
    {/* Progress bar */}
    <Grid item xs={12} md={12} lg={12}>
      <Paper sx={paperStyle}>
        <Title>Relative distance</Title>
        <ProgressBar completed={4500} id={POSITION_METRIC_ID} metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />
      </Paper>
    </Grid>

    {/* Log info */}
    <Grid item xs={12} md={7} lg={7}>
      <Paper sx={paperStyle}>
        <LogInfo />
      </Paper>
    </Grid>

    {/* Rel Velocity Graph */}
    <Grid item xs={12} md={5} lg={5}>
      <Paper sx={paperStyle}>
        <Title>Relative velocity</Title>
        <LeanspaceWidget widgetId={REL_VELOCITY_DASHBOARD} />
      </Paper>
    </Grid>
  </>)
};

export default MainDashboard;
