import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ActionButton from './ActionButton';

const paperStyle = { p: 2, display: 'flex', flexDirection: 'column' };

export default function ArmActionPanel() {
  return <Grid item xs={12} md={12} lg={12}  sx={{paddingTop: 3}}>
  <Paper sx={paperStyle}>
    <ActionButton type="reset" body={{i: -1, open: false}}>
      Reset Simulator
    </ActionButton>
  </Paper>
</Grid>
}
