import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import ActionButton from './ActionButton';

const paperStyle = { p: 2, display: 'flex', flexDirection: 'column' };

class ThrustActionPanel extends React.Component {

  constructor(props){
    super(props);
    this.state = {'value': '0', 'valid': true};
    this.didChange = this.didChange.bind(this);
  }


  didChange(event) {
    const newValue = event.target.value;
    this.setState({value: newValue});
    this.setState({valid: !isNaN(+newValue)});
  }

  render(){
    return (
      <Grid item xs={12} md={12} lg={12} sx={{paddingTop: 3}}>
        <Paper sx={paperStyle}>
          <TextField
            value={this.state.value}
            color={this.state.valid?"primary":"error"}
            onChange={this.didChange}
            label="Thrust duration"
            id="outlined-start-adornment"
            sx={{ mb: 1, mt: 1 }}
            InputProps={{
              startAdornment: <InputAdornment position="start">ms</InputAdornment>,
            }}
          />
          <ActionButton
            type="thrust"
            body={{forward: true, time: this.state.value}}
            disabled={!this.state.valid}
          >
            Thrust forwards
          </ActionButton>
          <ActionButton
            type="thrust"
            body={{forward: false, time: this.state.value}}
            disabled={!this.state.valid}
            sx={{marginTop: 1}}
          >
            Thrust backwards
          </ActionButton>
        </Paper>
      </Grid>
    );
  }

}

export default ThrustActionPanel;
