import * as React from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';
import DashboardIcon from '@mui/icons-material/Dashboard';
import WysiwygIcon from '@mui/icons-material/Wysiwyg';
import BatteryCharging60Icon from '@mui/icons-material/BatteryCharging60';
import FlashOnIcon from '@mui/icons-material/FlashOn';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import MyLocationIcon from '@mui/icons-material/MyLocation';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';
import SmartToyIcon from '@mui/icons-material/SmartToy';

function MenuItem(props) {
  return (
    <ListItem button onClick={()=>props.onPress(props.name)}>
      <ListItemIcon>
        <props.Icon color={props.color || "secondary"} />
      </ListItemIcon>
      <ListItemText primary={props.name} />
    </ListItem>
  );
}

export function MainListItem(props) {
  return (<MenuItem
    Icon={DashboardIcon}
    name="Dashboard"
    color="primary"
    onPress={props.onPress}/>);
  }

export function FirstListItems(props){
  return (<>
      <ListSubheader inset>Components</ListSubheader>
      <MenuItem Icon={BatteryCharging60Icon} name="Battery" onPress={props.onPress}/>
      <MenuItem Icon={WbSunnyIcon} name="Arrays" onPress={props.onPress}/>
      <MenuItem Icon={FlashOnIcon} name="Propulsion" onPress={props.onPress}/>
      <MenuItem Icon={MyLocationIcon} name="Position" onPress={props.onPress}/>
      <MenuItem Icon={PrecisionManufacturingIcon} name="Arms" onPress={props.onPress}/>
    </>);
}

export function SecondListItems(props){
  return (<>
      <ListSubheader inset>Interface</ListSubheader>
      <MenuItem Icon={WysiwygIcon} name="Commands" onPress={props.onPress}/>
      <MenuItem Icon={SmartToyIcon} name="Debug" onPress={props.onPress}/>
    </>);
};
