import * as React from 'react';
import Typography from '@mui/material/Typography';

const ID = "abb9cfd6-fd55-4a1b-ace6-731bbe5306f5"

const ArmsIndicator1 = ({ metrics, setMetrics, metricsData }) => {
  const [data, setData] = React.useState(false)

  React.useEffect(() => {
    if (!metrics.find(metric => metric === ID)) {
      metrics.push(ID)
      setMetrics(metrics)
    }

    if (metricsData) {
      setData(metricsData[ID])
    }
  }, [metricsData, metrics, setMetrics])

  return (
    (data === true) ? <Typography style={{ padding: '5px', color: 'green' }} >Open </Typography> :
      <Typography style={{ padding: '5px', color: 'red' }}>Closed </Typography>
  )
}
export default ArmsIndicator1;

// id Relative vel "56e0abb0-7eef-496f-af48-9142b423eae0"

// id vespa distance "9d514368-b41d-41cb-b8f7-d7f8d1281ef5"