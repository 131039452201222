import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Title';
import LeanspaceWidget from '../LeanspaceWidget';

const ENERGY_DASHBOARD   = "c1aada64-0842-4fa4-b1db-4ffb1f325587";
const BATTERY_USAGE = "a1bb75d3-a88d-49af-b5de-319f19c043f7";
const BATTERY_TEMPERATURE = "ac567f2f-8edf-441f-8630-aec91f602098";

export default function Battery() {
  const extraStyle = { p: 2, display: 'flex', flexDirection: 'column', paddingBottom: '65px' };

  return (
    <>
      {/* Battery Usage */}
      <Grid item xs={12} md={12} lg={6}>
        <Paper sx={extraStyle}>
          <Title>Battery charge</Title>
          <LeanspaceWidget widgetId={BATTERY_USAGE} chartSyncId="Battery" />
        </Paper>
      </Grid>

      {/* Battery Temperature */}
      <Grid item xs={12} md={12} lg={6}>
        <Paper sx={extraStyle}>
          <Title>Battery Temperature</Title>
          <LeanspaceWidget widgetId={BATTERY_TEMPERATURE} chartSyncId="Battery" />
        </Paper>
      </Grid>

      {/* CPU Graph */}
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={extraStyle}>
          <Title>CPU Usage</Title>
          <LeanspaceWidget widgetId={ENERGY_DASHBOARD} chartSyncId="Battery" />
        </Paper>
      </Grid>
    </>
  );
}
