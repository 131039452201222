import * as React from 'react';

import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import { useQuery } from 'react-query'
import { fetchMetricValues } from '../../API'

import theme from '../../Theme';
import SideBars from './SideBars';
import TopBar from '../TopBar';
import Copyright from '../Copyright';

import MainDashboard from '../Pages/MainDashboard';
import Battery from '../Pages/Battery';
import Arrays from '../Pages/Arrays';
import Propulsion from '../Pages/Propulsion';
import CommandDetails from '../Pages/CommandDetails';
import Position from '../Pages/Position';
import Arms from '../Pages/Arms';
import Debug from '../Pages/Debug';

const Dashboard = () => {

  const [page, setPage] = React.useState('Dashboard');
  const [metrics, setMetrics] = React.useState([]);

  const { data: metricsData } = useQuery(
    'metrics',
    async () => {
      const response = await fetchMetricValues(metrics)
      return response
    },
    { refetchInterval: 5000 }
  )

  function getCurrentPageElement() {
    if (page === 'Dashboard') {
      return <MainDashboard metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />;
    } else if (page === "Battery") {
      return <Battery />
    } else if (page === "Arrays") {
      return <Arrays />;
    } else if (page === "Propulsion") {
      return <Propulsion />;
    } else if (page === 'Position') {
      return <Position />;
    } else if (page === 'Commands') {
      return <CommandDetails metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />;
    } else if (page === 'Arms') {
      return <Arms />;
    } else if (page === 'Debug') {
      return <Debug metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />;
    }
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <SideBars onPress={(pageValue) => setPage(pageValue)} />
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              <TopBar metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />
              {getCurrentPageElement()}
            </Grid>

            <Copyright sx={{ pt: 4 }} />
          </Container>
        </Box>)
      </Box>
    </ThemeProvider>
  );

}

export default Dashboard;

// New comment
