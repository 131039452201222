import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ActionButton from './ActionButton';

const paperStyle = { p: 2, display: 'flex', flexDirection: 'column' };

const ArmActionSubPanel = (props) => {
  const open = props.open;
  return (
    <Grid item xs={12} md={12} lg={12}  sx={{paddingTop: 3}}>
      <Paper sx={paperStyle}>
        <ActionButton type="arms" body={{i: -1, open: open}}>
          {props.label}
        </ActionButton>
        <ActionButton sx={{marginTop: 1}} type="arms" body={{i: 0, open: open}}>
          1
        </ActionButton>
        <ActionButton sx={{marginTop: 1}} type="arms" body={{i: 1, open: open}}>
          2
        </ActionButton>
        <ActionButton sx={{marginTop: 1}} type="arms" body={{i: 2, open: open}}>
          3
        </ActionButton>
        <ActionButton sx={{marginTop: 1}} type="arms" body={{i: 3, open: open}}>
          4
        </ActionButton>
      </Paper>
    </Grid>
  );

}


export default function ArmActionPanel() {
  return <>
    <ArmActionSubPanel label={"Open all arms"} open={true} />
    <ArmActionSubPanel label={"Close all arms"} open={false} />
  </>
}
