import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Title';
import LeanspaceWidget from '../LeanspaceWidget';


const ARM_STATUS   = "11a47491-35ad-4f3a-bea0-456c208e2a20";
const ENEGY_DASHBOARD = "edec7f2b-70da-49a2-a2aa-a6e1cfef8a51";

export default function Arms() {
  const extraStyle = { p: 2, display: 'flex', flexDirection: 'column', paddingBottom: '65px' };
  
  return (
    <>
      {/* Battery Usage */}
      <Grid item xs={12} md={12} lg={6}>
        <Paper sx={extraStyle}>
          <Title>Arm Power Usage</Title>
          <LeanspaceWidget widgetId={ARM_STATUS} chartSyncId="Arms" />
        </Paper>
      </Grid>

      {/* CPU Graph */}
      <Grid item xs={12} md={12} lg={6}>
        <Paper sx={extraStyle}>
          <Title>Energy consumed</Title>
          <LeanspaceWidget widgetId={ENEGY_DASHBOARD} chartSyncId="Arms" />
        </Paper>
      </Grid>
    </>);
}
