import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    icon: {
      main: '#e0e0e0'
    },
    light: {
      main: '#e0e0e0'
    },
    primary: {
      main: '#ba68c8',
    },
    secondary: {
      main: '#ce93d8',
      contrastText: 'rgba(0,0,0,0.87)',
    },
    background: {
      default: '#37474f',
      paper: '#263238',
    },
    divider: '#37474f',
    text: {
      primary: '#e0e0e0',
      secondary: '#90a4ae',
      disabled: '#455a64',
    },
    error: {
      main: '#d81b60',
    },
    success: {
      main: '#ffca28',
    },
    green: {
      main: '#58e34b'
    },
    yellow: {
      main: '#fff833'
    },
    red: {
      main: '#f54842'
    },
  },
})

export default theme;
