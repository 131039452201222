import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Title';
import LeanspaceWidget from '../LeanspaceWidget';

const POSITION_DASHBOARD = "70fcd703-708a-46eb-851a-312d64afad01";

const paperStyle = { p: 2, display: 'flex', flexDirection: 'column', paddingBottom: '65px' };

export default function MainDashboard() {

  return (
    <>
      {/* Position Graph */}
      <Grid item xs={12} md={7} lg={12}>
        <Paper sx={paperStyle}>
        <Title>Geocentric Position </Title>
          <LeanspaceWidget widgetId={POSITION_DASHBOARD} />
        </Paper>
      </Grid>
    </>);
}
