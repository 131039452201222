import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import LogInfo from '../LogInfo';
import ArmActionPanel from '../ArmActionPanel';
import ThrustActionPanel from '../ThrustActionPanel';
import PauseMovementPanel from '../PauseMovementPanel';
import ResetPanel from '../ResetPanel';
import ProgressBar from '../ProgressBar';
import Title from '../Title';
import StartFarPhaseButton from '../StartFarPhaseButton';

const POSITION_METRIC_ID = "9d514368-b41d-41cb-b8f7-d7f8d1281ef5";

const paperStyle = { p: 2, display: 'flex', flexDirection: 'column', paddingBottom: '65px' };

const CommandDetails = ({ metrics, setMetrics, metricsData }) => {

  return (


    <>

      {/* Progress bar */}
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={paperStyle}>
          <Title>Relative distance</Title>
          <ProgressBar completed={4500} id={POSITION_METRIC_ID} metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />
        </Paper>
      </Grid>

      <Grid item xs={12} md={4} lg={3}>
        {/* Open arms */}
        <StartFarPhaseButton />
        <ArmActionPanel />
        <ThrustActionPanel />
        <PauseMovementPanel metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />
        <ResetPanel />
      </Grid>

      {/* Log info */}
      <Grid item xs={12} md={8} lg={9}>
        <Paper sx={{
          p: 2,
          display: 'flex',
          flexDirection: 'column',
          overflowX: 'scroll'
        }}>
          <LogInfo />
        </Paper>
      </Grid>
    </>
  );
}

export default CommandDetails
