import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import ky from 'ky';
import * as React from 'react';
import { WEBHOOK_URL } from '../config';

const paperStyle = { p: 2, display: 'flex', flexDirection: 'column' };

class StartFarPhaseButton extends React.Component {

  constructor(props) {
    super(props);
    this.state = { 'enabled': true }
    this.doAction = this.doAction.bind(this);
  }

  doAction() {
    this.setState({ enabled: false })
    ky.post(
      WEBHOOK_URL,
      { json: { "type": "start-far-phase" } }
    ).json().then(rows => this.setState({ enabled: true }))
  }

  render() {
    return (
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={paperStyle}>
          <Button
            {...this.props}
            variant="contained"
            onClick={this.doAction}
            disabled={!this.state.enabled}
            sx={{ marginTop: 1 }}
          >
            Start Far Phase
          </Button>
        </Paper>
      </Grid>
    );
  }
}

export default StartFarPhaseButton;
