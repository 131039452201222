import Auth from "@aws-amplify/auth";
import Amplify from "@aws-amplify/core";
import CssBaseline from "@material-ui/core/CssBaseline";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { QueryClient, QueryClientProvider } from 'react-query';
import { fetchTenantInformations } from "./API";
import Login from "./Components/Login";
import Dashboard from "./Components/Dashboard/Dashboard";
import { AWS_REGION } from './config';
import UserContext from "./Contexts/UserContext";
import theme from "./Theme"

const queryClient = new QueryClient()

function App() {
  const [isAmplifyReady, setAmplifyReady] = useState(false);
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [token, updateToken] = useState(null);
  const [didNotFindTenant, setDidNotFindTenant] = useState(false);

  useEffect(function loadTenantConfig() {
    setDidNotFindTenant(false);
    const load = async () => {
      try {


        const tenantInfos = await fetchTenantInformations();

        await Amplify.configure({
          Auth: {
            ...tenantInfos.authInfo,
            userPoolWebClientId: tenantInfos.authInfo.clientAppId,
            region: tenantInfos.authInfo.region || AWS_REGION,
            accountId: tenantInfos.tenantKey
          }
        });
        setAmplifyReady(true);


        const currentUser = await Auth.currentAuthenticatedUser();
        if (currentUser.signInUserSession) {
          const {
            accessToken: { jwtToken }
          } = currentUser.signInUserSession;
          updateToken(jwtToken);
          setLoggedIn(true);
        }
      } catch (error) {
        setDidNotFindTenant(true);
      }
    };
    load();
  }, []);

  const onLogin = (token) => {
    updateToken(token);
    setLoggedIn(true);
  };

  const contextValue = {
    token,
    login: onLogin,
    isLoggedIn
  };

  return isAmplifyReady ? (
    <ThemeProvider theme={theme}>
      <UserContext.Provider value={contextValue}>
        <QueryClientProvider client={queryClient}>
          <CssBaseline />
          {isLoggedIn ? <Dashboard /> : <Login />}
        </QueryClientProvider>
      </UserContext.Provider>
    </ThemeProvider>
  ) : didNotFindTenant ? 'Failed to load tenant information — did you update the config.js file?' : null;
}

export default App;
