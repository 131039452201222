import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import WbSunnyIcon from '@mui/icons-material/WbSunny';
import Brightness3Icon from '@mui/icons-material/Brightness3';


const ORBITAL_PERIOD_ID = "c191c58f-4ab3-456e-a726-9c6184ae243e";
const IS_ECLIPSE_ID = "e29635ba-e5ae-4a6c-b6f2-1f44492b6509";

const EclipseIndicator = ({ metrics, setMetrics, metricsData }) => {

  const [orbitalPeriod, setOrbitalPeriod] = React.useState('0');
  const [isEclipse, setIsEclipse] = React.useState(true);
  const [counter, setCounter] = React.useState('?');

  React.useEffect(() => {
    if (!metrics.find(metric => metric === ORBITAL_PERIOD_ID)) {
      metrics.push(ORBITAL_PERIOD_ID)
      setMetrics(metrics)
    }

    if (!metrics.find(metric => metric === IS_ECLIPSE_ID)) {
      metrics.push(IS_ECLIPSE_ID)
      setMetrics(metrics)
    }

    if (metricsData) {
      setOrbitalPeriod(metricsData[ORBITAL_PERIOD_ID])
      const previousEclipseState = isEclipse;
      setIsEclipse(metricsData[IS_ECLIPSE_ID])
      if (previousEclipseState !== metricsData[IS_ECLIPSE_ID]) {
        setCounter(metricsData[ORBITAL_PERIOD_ID] / 2);
      }
    }
  }, [metricsData, isEclipse, metrics, setMetrics])


  React.useEffect(() => {
    const interval = setInterval(() => {
      if (counter !== '?') {
        if (counter <= 0) {
          setCounter(orbitalPeriod / 2)
        } else {
          setCounter(counter - 1)
        }
      }
    }, 1000);
    return () => clearInterval(interval);
  }, [counter, orbitalPeriod]);

  return (
    <Grid container spacing={3} sx={{ pt: "14px" }}>
      <Grid item xs={6} md={6} lg={6}>
        <Typography style={{ padding: '5px' }}>
          {isEclipse ?
            <><Brightness3Icon /> Eclipse</> :
            <><WbSunnyIcon /> No Eclipse</>}
        </Typography>
      </Grid>
      <Grid item xs={6} md={6} lg={6}>
        <Typography>Orbital Period: {orbitalPeriod}</Typography>
        <Typography>Time Until Next: {counter}</Typography>
      </Grid>
    </Grid>
  );
}

export default EclipseIndicator;
