import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Title';
import LeanspaceWidget from '../LeanspaceWidget';

const ARRAY_temperature = "545cfec0-9136-420f-adbb-02e7e351a438";

export default function Arrays() {
  const extraStyle = { p: 2, display: 'flex', flexDirection: 'column', paddingBottom: '65px' };

  return (
    <>
      {/* Array Temperature */}
      <Grid item xs={12} md={12} lg={12}>
        <Paper sx={extraStyle}>
        <Title>Arrays Temperature </Title>
          <LeanspaceWidget widgetId={ARRAY_temperature} />
        </Paper>
      </Grid>
    </>);
}
