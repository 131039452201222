import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import DeleteIcon from '@mui/icons-material/Delete';
import IconButton from '@mui/material/IconButton';
import CircleIcon from '@mui/icons-material/Circle';
import RefreshIcon from '@mui/icons-material/Refresh';
import ky from 'ky';
import { WEBHOOK_URL } from '../config';

const LogInfo = () => {
  const [rows, setRows] = React.useState([])

  React.useEffect(() => {
    tryUpdate()
    // remove auto refresh to avoid hitting the quota
  }, []);

  async function onPress(index) {
    const response = await ky.delete(
      WEBHOOK_URL,
      { json: { 'type': 'delete-log', 'i': index } }
    ).json();
    setRows(response.logs);
  }

  async function tryUpdate() {
    try {
      const response = await ky.post(
        WEBHOOK_URL,
        { json: { 'type': 'logs' } }
      ).json();
      setRows(response.logs);
    } catch (e) {
      console.error("requested failed with", e)
    }
  }

  const formatDate = date => new Date(date).toLocaleString('en-GB', {
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    hour24: true,
  });
  const now = new Date();
  const statusColor = date => {
    date = new Date(date);
    if (date.getTime() <= now.getTime() - 1000) {
      return "green";
    } else if (date.getTime() >= now.getTime() + 1000) {
      return "red";
    } else {
      return "yellow";
    }
  }

  return (
    <>

      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ padding: "0px 0px 0px 0px" }}>
              <IconButton
                size="small"
                color="light"
                onClick={tryUpdate}
              >
                <RefreshIcon />
              </IconButton>
            </TableCell>
            <TableCell>Action</TableCell>
            <TableCell>Date Sent</TableCell>
            <TableCell>Execution Time</TableCell>
            <TableCell>Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell >
                <CircleIcon
                  style={{ fontSize: 10 }}
                  color={statusColor(row.execution_time)}
                />
              </TableCell>
              <TableCell>{row.name}</TableCell>
              <TableCell>{formatDate(row.date)}</TableCell>
              <TableCell>{formatDate(row.execution_time)}</TableCell>
              <TableCell>
                <IconButton
                  aria-label="delete"
                  size="large"
                  color="light"
                  onClick={() => onPress(index)}
                >
                  <DeleteIcon />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </>
  );
}

export default LogInfo;
