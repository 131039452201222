import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ky from 'ky';

import { WEBHOOK_URL } from '../config';

const RELATIVE_VELOCITY_ID = '56e0abb0-7eef-496f-af48-9142b423eae0';
const paperStyle = { p: 2, display: 'flex', flexDirection: 'column' };

const PauseMovementPanel = ({ metrics, setMetrics, metricsData }) => {
  const [data, setData] = React.useState('?')
  const [enabled, setEnabled] = React.useState(true)

  React.useEffect(() => {
    if (!metrics.find(metric => metric === RELATIVE_VELOCITY_ID)) {
      metrics.push(RELATIVE_VELOCITY_ID)
      setMetrics(metrics)
    }

    if (metricsData) {
      setData(metricsData[RELATIVE_VELOCITY_ID])
    }
  }, [metricsData, metrics, setMetrics])

  function doAction() {
    let thrust = 3000.0 / 0.01 * (1.0 - 1.0 / (Math.exp(Math.abs(data) / 2000.0)));
    var body = {}
    if (data > 0) {
      body = { "type": "thrust", "forward": false, "time": thrust * 1000 }
    } else {
      body = { "type": "thrust", "forward": true, "time": thrust * 1000 }
    }
    setEnabled(false)
    ky.post(
      WEBHOOK_URL,
      { json: body }
    ).json().then(rows => setEnabled(true))
  }

  return (
    <Grid item xs={12} md={12} lg={12} sx={{ paddingTop: 3 }}>
      <Paper sx={paperStyle}>
        <Typography>
          {
            data === "?" ?
              `Fetching speed...` :
              `Current speed of ${(+data).toFixed(3)}m/s`
          }
        </Typography>
        <Button
          variant="contained"
          onClick={doAction}
          disabled={!enabled || data === "?"}
          sx={{ marginTop: 1 }}
        >
          Stop Satellite
        </Button>
      </Paper>
    </Grid>
  );
}

export default PauseMovementPanel;
