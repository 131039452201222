import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Title from '../Title';
import LeanspaceWidget from '../LeanspaceWidget';

const NOZZLE_TEMPERATURE = "3fc6526b-7177-4b93-9ce2-cafae7865b4d";
const MASS_DASHBOARD = "c5090ee6-efd8-4315-8900-3bc3ce4b3285";


const paperStyle = { p: 2, display: 'flex', flexDirection: 'column', paddingBottom: '65px' };

export default function MainDashboard() {
  const extraStyle = { p: 2, display: 'flex', flexDirection: 'column', paddingBottom: '65px' };

  return (
    <>
      {/* NOZZLE TEMPERATURE */}
      <Grid item xs={12} md={12} lg={6}>
        <Paper sx={extraStyle}>
        <Title>Nozzle Temperature</Title>
          <LeanspaceWidget widgetId={NOZZLE_TEMPERATURE} chartSyncId="Propulsion" />
        </Paper>
      </Grid>

      {/* Mass Graph */}
      <Grid item xs={12} md={12} lg={6}>
        <Paper sx={paperStyle}>
        <Title>Mass</Title>
          <LeanspaceWidget widgetId={MASS_DASHBOARD} chartSyncId="Propulsion" />
        </Paper>
      </Grid>
    </>
  );
}
