import * as React from 'react';
import Button from '@mui/material/Button';
import ky from 'ky';
import { WEBHOOK_URL } from '../config';

class ActionButton extends React.Component {

  constructor(props){
    super(props);
    this.state = {'enabled': true}
    this.doAction = this.doAction.bind(this)
  }


  doAction() {
    this.setState({enabled: false})
    ky.post(
      WEBHOOK_URL,
      {json: {'type': this.props.type, ...this.props.body}}
    ).json().then(rows => this.setState({enabled: true}))
  }

  render(){
    return (
      <Button
        {...this.props}
        variant="contained"
        onClick={this.doAction}
        disabled={!this.state.enabled || this.props.disabled===true}
      >
        {this.props.children}
      </Button>
    );
  }
}

export default ActionButton;
