import * as React from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Clock from './Clock';
import EclipseIndicator from './EclipseIndicator';
import ArmsIndicator1 from './arms1';
import ArmsIndicator2 from './arms2';
import ArmsIndicator3 from './arms3';
import ArmsIndicator4 from './arms4';

const paperStyle = { p: 2, display: 'flex', flexDirection: 'column' };

const TopBar = ({ metrics, setMetrics, metricsData }) => {
  return (<Grid item xs={12} md={12} lg={12}>
    <Grid container spacing={3}>

      {/* Clock */}
      <Grid item xs={12} md={4} lg={4}>
        <Paper sx={paperStyle} style={{ height: "100%" }}>
          <Clock />
        </Paper>
      </Grid>

      {/* Eclipse */}
      <Grid item xs={12} md={4} lg={4}>
        <Paper sx={paperStyle} style={{ height: "100%" }}>
          <EclipseIndicator metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />
        </Paper>
      </Grid>

      {/* Arms */}
      <Grid item xs={12} md={4} lg={4}>
        <Paper sx={paperStyle} style={{ height: "100%" }}>
          <table>
            <tbody>
              <tr>
                <th>Arm 1 </th>
                <th>Arm 2 </th>
                <th>Arm 3 </th>
                <th>Arm 4 </th>
              </tr>
              <tr>
                <th><ArmsIndicator1 metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} /></th>
                <th><ArmsIndicator2 metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} />  </th>
                <th><ArmsIndicator3 metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} /></th>
                <th><ArmsIndicator4 metrics={metrics} setMetrics={setMetrics} metricsData={metricsData} /></th>
              </tr>
            </tbody>
          </table>
        </Paper>
      </Grid>
    </Grid>
  </Grid>
  );
}

export default TopBar;
