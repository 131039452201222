import Auth from '@aws-amplify/auth';
import LeanspaceWidget from '@leanspace/dashboard-sdk/lib/vanilla';
import React from 'react';
import { useQuery } from "react-query";
import { API_BASE_URL, TENANT } from '../config';

const REFRESH_INTERVAL = 5000;

const getToken = async () => {
  const user = await Auth.currentAuthenticatedUser({
    bypassCache: false,
  });

  const {
    accessToken: { jwtToken },
  } = user.signInUserSession;
  return jwtToken;
};

const CurrentLeanspaceWidget = ({
  widgetId, chartSyncId
}) => {

  const { isSuccess, data: token } = useQuery('widget-token', getToken);

  return (
    <>
      {isSuccess && (<LeanspaceWidget
        onTokenRefreshRequest={getToken}
        apiToken={token}
        apiUrl={API_BASE_URL}
        tenantId={TENANT}
        widgetConfiguration={widgetId}
        dateRange='from 29 minutes ago to now'
        onDateRangeChange={() => { }}
        refetchInterval={REFRESH_INTERVAL}
        chartSyncId={chartSyncId}
      />)}
    </>);
};

export default CurrentLeanspaceWidget;
