import { withAPIClient } from './helpers';

export const fetchNodes = (searchParams) =>
  withAPIClient((client) =>
    client.get(`metrics-repository/nodes`, { searchParams }).json()
  );

export const fetchMetrics = (searchParams) =>
  withAPIClient((client) =>
    client.get(`metrics-repository/metrics`, { searchParams }).json()
  );

export const fetchMetricValues = async (metricIds) => {
  //Query for last value of a metric
  const metricFields = metricIds.map(metricId => {
    const normalizedMetricId = metricId.replace(/-/gi, '_');
    return normalizedMetricId;

  })
  const timestampField = `timestamp`;

  const queryLastValue = () => {
    return {
      dimensions: [...metricFields, timestampField],
      order: { [timestampField]: 'DESC' },
      offset: 0,
      limit: 1
    }
  };

  try {
    const value = await withAPIClient((client) =>
      client.post('metrics-repository/query', { json: queryLastValue() }).json()
    )
    const metrics = Object.fromEntries(metricFields.map((metricField, index) => {
      return [metricIds[index], value['data'][0][metricField]];
    }))
    return metrics
  } catch (error) {
    console.log("Caught ky error")
    return NaN;
  }
}

// You can add more Leanspace API calls following this pattern. The withAPIClient function returns an instance of ky (https://github.com/sindresorhus/ky) that is pre-configured with the base API URL and an up-to-date token.

export { fetchTenantInformations } from './helpers'
